import { React, Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
/**
 * @params
 * showBreadcrumb 是否显示面包屑导航
 * breadCrumbData 面包屑导航数据
 * showSider 是否展示侧边栏
 * modeBelong 所属模块
 * sideKey 侧边栏所属路由
 * group 组别
 * title 用于网页title
 * authCode 权限编码
 */

const routes = [
  {
    path: "/login",
    auth: false,
    component: lazy(() => import("@/page/login/index")),
    title: "login",
  },
  {
    path: "/403",
    auth: true,
    component: lazy(() => import("@/page/commonPage/notPermission")),
    showSider: false,
    title: "403",
  },
  {
    path: "/",
    auth: true,
    component: lazy(() => import("@/Root")),
    showBreadcrumb: false,
    showSider: false,
    children: [
      {
        path: "/dashboard",
        auth: true,
        component: lazy(() => import("@/page/dashboard/index")),
        showBreadcrumb: false,
        showSider: false,
        title: "dashboard",
      },

      {
        path: "/deviceDetail/:key",
        auth: true,
        authCode: 19111, //10110,
        component: lazy(() => import("@/page/deviceDetail/index")),
        matchPath: "/deviceDetail",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail", active: true },
        ],
        title: "deviceDetail",
      },

      {
        path: "/deviceDetail/editMonitorOption",
        auth: true,
        authCode: 19111, //10110,
        component: lazy(() => import("@/page/deviceDetail/editMonitorOption")),
        showBreadcrumb: true,
        title: "editMonitorOption",
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "edit_monitor_Item", active: true },
        ],
      },

      {
        path: "/deviceDetail/editMonitorOption/EditMonitorOptionModel",
        auth: true,
        authCode: 19111, //19111
        component: lazy(() =>
          import("@/page/deviceDetail/editMonitorOptionModel")
        ),
        showBreadcrumb: true,
        showSider: false,
        modeBelong: "userManger",
        title: "editMonitorOptionModel",
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "edit_monitor_Item" },
          { title: "config_template", active: true },
        ],
      },

      {
        path: "/role",
        auth: true,
        group: "organization",
        authCode: 10210,
        component: lazy(() => import("@/page/role/index")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/role",
        showBreadcrumb: true,
        title: "role",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "role", active: true },
        ],
      },

      {
        path: "/role/add",
        auth: true,
        group: "organization",
        authCode: 10211,
        component: lazy(() => import("@/page/role/add")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/role",
        showBreadcrumb: true,
        title: "Createrole",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "role" },
          { title: "role_add", active: true },
        ],
      },

      {
        path: "/role/edit/:key",
        auth: true,
        group: "organization",
        authCode: 10212,
        matchPath: "/role/edit",
        component: lazy(() => import("@/page/role/edit")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/role",
        showBreadcrumb: true,
        title: "Editrole",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "role" },
          { title: "role_edit", active: true },
        ],
      },

      {
        path: "/user",
        auth: true,
        group: "organization",
        authCode: 10110,
        component: lazy(() => import("@/page/user/index")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/user",
        showBreadcrumb: true,
        title: "user",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "user_manage", active: true },
        ],
      },

      {
        path: "/user/add",
        auth: true,
        group: "organization",
        authCode: 10111,
        component: lazy(() => import("@/page/user/add")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/user",
        showBreadcrumb: true,
        title: "createUser",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "user_manage" },
          { title: "user_add", active: true },
        ],
      },

      {
        path: "/user/edit/:key",
        auth: true,
        group: "organization",
        authCode: 10112,
        matchPath: "/user/edit",
        component: lazy(() => import("@/page/user/edit")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/user",
        showBreadcrumb: true,
        title: "EditUser",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "user_manage" },
          { title: "user_edit", active: true },
        ],
      },

      {
        path: "/user/settingCinema/:key",
        auth: true,
        group: "organization",
        authCode: 10114,
        matchPath: "/user/settingCinema",
        component: lazy(() => import("@/page/user/settingCinema")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/user",
        showBreadcrumb: true,
        title: "settingCinemaByUser",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "user_manage" },
          { title: "config_cinema", active: true },
        ],
      },

      {
        path: "/company",
        auth: true,
        authCode: 10310,
        group: "organization",
        component: lazy(() => import("@/page/company/index")),
        showSider: true,
        modeBelong: "userManger",
        sideKey: "/company",
        showBreadcrumb: true,
        title: "company",
        breadcrumbData: [
          { title: "config" },
          { title: "organization" },
          { title: "company", active: true },
        ],
      },

      {
        path: "/cinemaManage",
        auth: true,
        group: "cinema",
        authCode: 10410,
        component: lazy(() => import("@/page/cinema/index")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaManage",
        showBreadcrumb: true,
        title: "cinemaManage",
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "theater", active: true },
        ],
      },

      {
        path: "/cinemaManage/add",
        auth: true,
        group: "cinema",
        authCode: 10411,
        component: lazy(() => import("@/page/cinema/add")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaManage",
        showBreadcrumb: true,
        title: "CreateCinema",
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "theater" },
          { title: "cinema_add", active: true },
        ],
      },

      {
        path: "/cinemaManage/edit/:key",
        auth: true,
        group: "cinema",
        authCode: 10412,
        component: lazy(() => import("@/page/cinema/edit")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaManage",
        matchPath: "/cinemaManage/edit",
        showBreadcrumb: true,
        title: "EditCinema",
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "theater" },
          { title: "cinema_edit", active: true },
        ],
      },

      {
        path: "/cinemaHall",
        auth: true,
        group: "cinema",
        authCode: 10510,
        component: lazy(() => import("@/page/cinemaHall/index")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaHall",
        showBreadcrumb: true,
        title: "cinemaHall",
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "screen", active: true },
        ],
      },

      {
        path: "/cinemaHall/add",
        auth: true,
        group: "cinema",
        authCode: 10511,
        component: lazy(() => import("@/page/cinemaHall/add")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaHall",
        showBreadcrumb: true,
        title: "CreateCinemaHall",
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "screen" },
          { title: "screen_add", active: true },
        ],
      },

      {
        path: "/cinemaHall/edit/:key",
        auth: true,
        group: "cinema",
        authCode: 10512,
        component: lazy(() => import("@/page/cinemaHall/edit")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaHall",
        matchPath: "/cinemaHall/edit",
        title: "EditCinemaHall",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "screen" },
          { title: "screen_edit", active: true },
        ],
      },

      {
        path: "/cinemaHall/upload",
        auth: true,
        group: "cinema",
        authCode: 10514,
        component: lazy(() => import("@/page/cinemaHall/upload")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaHall",
        title: "UploadCinemaHall",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "screen_upload", active: true },
        ],
      },

      {
        path: "/cinemaManage/upload",
        auth: true,
        group: "cinema",
        authCode: 10414,
        component: lazy(() => import("@/page/cinema/upload")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinema",
        showBreadcrumb: true,
        title: "UploadCinema",
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "cinema_upload", active: true },
        ],
      },

      {
        path: "/chains",
        auth: true,
        group: "cinema",
        authCode: 10610,
        component: lazy(() => import("@/page/chains/index")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/chains",
        showBreadcrumb: true,
        title: "chains",
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "circuit", active: true },
        ],
      },

      {
        path: "/cinemaGroup",
        auth: true,
        group: "cinema",
        authCode: 10710,
        component: lazy(() => import("@/page/cinemaGroup/index")),
        showSider: true,
        modeBelong: "cinema",
        sideKey: "/cinemaGroup",
        title: "cinemaGroup",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "theater" },
          { title: "cinema_group", active: true },
        ],
      },

      {
        path: "/device",
        auth: true,
        group: "device",
        authCode: 10810,
        component: lazy(() => import("@/page/device/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/device",
        title: "device",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "equipment", active: true },
        ],
      },

      {
        path: "/device/add",
        auth: true,
        group: "device",
        authCode: 10811,
        component: lazy(() => import("@/page/device/add")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/device",
        title: "CreateDevice",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "equipment_add", active: true },
        ],
      },

      {
        path: "/device/edit/:key",
        auth: true,
        group: "device",
        authCode: 10812,
        component: lazy(() => import("@/page/device/edit")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/device",
        matchPath: "/device/edit",
        title: "EditDevice",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "equipment_edit", active: true },
        ],
      },

      {
        path: "/device/upload",
        auth: true,
        group: "device",
        authCode: 10814,
        component: lazy(() => import("@/page/device/upload")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/device",
        showBreadcrumb: true,
        title: "UploadDevice",
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "equipment_upload", active: true },
        ],
      },

      {
        path: "/deviceType",
        auth: true,
        group: "device",
        authCode: 10910,
        component: lazy(() => import("@/page/deviceType/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/deviceType",
        showBreadcrumb: true,
        title: "deviceType",
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "equipment_type", active: true },
        ],
      },

      {
        path: "/deviceModel",
        auth: true,
        group: "device",
        authCode: 11010,
        component: lazy(() => import("@/page/deviceModel/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/deviceModel",
        title: "deviceModel",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "model", active: true },
        ],
      },

      {
        path: "/deviceModel/add",
        auth: true,
        group: "device",
        authCode: 11011,
        component: lazy(() => import("@/page/deviceModel/add")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/deviceModel",
        title: "CreateDeviceModel",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "model" },
          { title: "model_add", active: true },
        ],
      },

      {
        path: "/deviceModel/edit/:key",
        auth: true,
        group: "device",
        authCode: 11012,
        component: lazy(() => import("@/page/deviceModel/edit")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/deviceModel",
        matchPath: "/deviceModel/edit",
        title: "EditDeviceModel",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "model" },
          { title: "model_edit", active: true },
        ],
      },

      {
        path: "/manuFactor",
        auth: true,
        group: "device",
        authCode: 12010,
        component: lazy(() => import("@/page/manuFactor/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/manuFactor",
        title: "manuFactor",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "manufacturers", active: true },
        ],
      },

      {
        path: "/manuFactor/add",
        auth: true,
        group: "device",
        authCode: 12011,
        component: lazy(() => import("@/page/manuFactor/add")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/manuFactor",
        showBreadcrumb: true,
        title: "CreateManuFactor",
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "manufacturers" },
          { title: "manufacturers_add", active: true },
        ],
      },

      {
        path: "/manuFactor/edit/:key",
        auth: true,
        group: "device",
        authCode: 12012,
        component: lazy(() => import("@/page/manuFactor/edit")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/manuFactor",
        matchPath: "/manuFactor/edit",
        title: "EditManuFactor",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "manufacturers" },
          { title: "manufacturers_edit", active: true },
        ],
      },

      {
        path: "/spareParts",
        auth: true,
        group: "device",
        authCode: 13010,
        component: lazy(() => import("@/page/spareParts/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/spareParts",
        title: "spareParts",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "parts", active: true },
        ],
      },

      {
        path: "/spareParts/add",
        auth: true,
        group: "device",
        authCode: 13011,
        component: lazy(() => import("@/page/spareParts/add")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/spareParts",
        showBreadcrumb: true,
        title: "CreateSpareParts",
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "parts" },
          { title: "parts_add", active: true },
        ],
      },

      {
        path: "/spareParts/edit/:key",
        auth: true,
        group: "device",
        authCode: 13012,
        component: lazy(() => import("@/page/spareParts/edit")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/spareParts",
        matchPath: "/spareParts/edit",
        showBreadcrumb: true,
        title: "EditSpareParts",
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "parts" },
          { title: "parts_edit", active: true },
        ],
      },

      {
        path: "/sparePartsType",
        auth: true,
        group: "device",
        authCode: 13010,
        component: lazy(() => import("@/page/sparePartsType/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/spareParts",
        showBreadcrumb: true,
        title: "sparePartsType",
        breadcrumbData: [
          { title: "config" },
          { title: "equipment" },
          { title: "parts" },
          { title: "parts_type", active: true },
        ],
      },

      {
        path: "/MibType",
        auth: true,
        group: "device",
        authCode: 14010,
        component: lazy(() => import("@/page/mibType/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/MibType",
        showBreadcrumb: true,
        title: "MibType",
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "mib_type_edit", active: true },
        ],
      },

      {
        path: "/LampInfo",
        auth: true,
        group: "device",
        authCode: 14020,
        component: lazy(() => import("@/page/lampInfo/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/LampInfo",
        showBreadcrumb: true,
        title: "LampInfo",
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "lamp_info", active: true },
        ],
      },

      {
        path: "/LampInfo/edit/:key",
        auth: true,
        group: "device",
        authCode: 14022,
        component: lazy(() => import("@/page/lampInfo/edit")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/LampInfo",
        matchPath: "/LampInfo/edit",
        title: "LampInfo",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "lamp_info", active: true },
        ],
      },

      {
        path: "/LampInfo/install/:key",
        auth: true,
        group: "device",
        authCode: 14024,
        component: lazy(() => import("@/page/lampInfo/install")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/LampInfo",
        matchPath: "/LampInfo/install",
        title: "LampInfo",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "lamp_info", active: true },
        ],
      },

      {
        path: "/LampModel",
        auth: true,
        group: "device",
        authCode: 14030,
        component: lazy(() => import("@/page/lampModel/index")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/LampModel",
        showBreadcrumb: true,
        title: "LampModel",
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "lamp_model", active: true },
        ],
      },

      {
        path: "/LampModel/add",
        auth: true,
        group: "device",
        authCode: 14031,
        component: lazy(() => import("@/page/lampModel/add")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/LampModel",
        title: "LampModel",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "lamp_model", active: true },
        ],
      },

      {
        path: "/LampModel/edit/:key",
        auth: true,
        group: "device",
        authCode: 14032,
        component: lazy(() => import("@/page/lampModel/edit")),
        showSider: true,
        modeBelong: "device",
        sideKey: "/LampModel",
        matchPath: "/LampModel/edit",
        title: "LampModel",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "deviceList" },
          { title: "deviceDetail" },
          { title: "lamp_model", active: true },
        ],
      },

      {
        path: "/faultType",
        auth: true,
        group: "fault",
        authCode: 15100,
        component: lazy(() => import("@/page/faultType/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/faultType",
        title: "faultType",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "fault_type", active: true },
        ],
      },

      {
        path: "/faultType/add",
        auth: true,
        group: "fault",
        authCode: 15102,
        component: lazy(() => import("@/page/faultType/add")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/faultType",
        title: "faultType",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "fault_type" },
          { title: "fault_type_add", active: true },
        ],
      },

      {
        path: "/faultType/edit/:key",
        auth: true,
        group: "fault",
        authCode: 15103,
        component: lazy(() => import("@/page/faultType/edit")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/faultType",
        matchPath: "/faultType/edit",
        title: "faultType",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "fault_type" },
          { title: "fault_type_edit", active: true },
        ],
      },

      {
        path: "/faultOption/:key",
        auth: true,
        group: "fault",
        authCode: 15105,
        component: lazy(() => import("@/page/faultOption/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/faultOption",
        title: "faultOption",
        matchPath: "/faultOption",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "fault_type" },
          { title: "fault_item", active: true },
        ],
      },

      {
        path: "/faultWarning",
        auth: true,
        group: "fault",
        authCode: 15210,
        component: lazy(() => import("@/page/faultWarning/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/faultWarning",
        title: "faultWarning",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "faule_alarm", active: true },
        ],
      },

      {
        path: "/alarmControl",
        auth: true,
        group: "fault",
        authCode: 15310,
        component: lazy(() => import("@/page/alarmControl/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/alarmControl",
        title: "alarmControl",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "equipment_control", active: true },
        ],
      },

      {
        path: "/faultAction",
        auth: true,
        group: "fault",
        authCode: 15410,
        component: lazy(() => import("@/page/faultAction/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/faultAction",
        title: "faultAction",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "snmp_setting", active: true },
        ],
      },

      {
        path: "/lightReport",
        auth: true,
        group: "fault",
        authCode: 15510,
        component: lazy(() => import("@/page/lightReport/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/lightReport",
        title: "lightReport",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "light_report", active: true },
        ],
      },

      {
        path: "/haltReport",
        auth: true,
        group: "fault",
        authCode: 15610,
        component: lazy(() => import("@/page/haltReport/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/haltReport",
        title: "haltReport",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "shutdown_report", active: true },
        ],
      },

      {
        path: "/haltNotice",
        auth: true,
        group: "fault",
        authCode: 15710,
        component: lazy(() => import("@/page/haltNotice/index")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/haltNotice",
        title: "haltNotice",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "shutdown_notice", active: true },
        ],
      },

      {
        path: "/haltNotice/add",
        auth: true,
        group: "fault",
        authCode: 15711,
        component: lazy(() => import("@/page/haltNotice/add")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/haltNotice",
        title: "haltNotice",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "shutdown_notice" },
          { title: "shutdown_notice_add", active: true },
        ],
      },

      {
        path: "/haltNotice/edit/:key",
        auth: true,
        group: "fault",
        authCode: 15711,
        component: lazy(() => import("@/page/haltNotice/edit")),
        showSider: true,
        modeBelong: "fault",
        sideKey: "/haltNotice",
        matchPath: "/haltNotice/edit",
        title: "haltNotice",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "fault_reportting" },
          { title: "shutdown_notice" },
          { title: "shutdown_notice_edit", active: true },
        ],
      },

      {
        path: "/systemSetting",
        auth: true,
        group: "system",
        authCode: 16110,
        component: lazy(() => import("@/page/systemSetting/index")),
        showSider: true,
        modeBelong: "system",
        sideKey: "/systemSetting",
        title: "systemSetting",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "system_preferences" },
          { title: "system_config", active: true },
        ],
      },

      {
        path: "/districtLanguageSetting",
        auth: true,
        group: "system",
        authCode: 16210,
        component: lazy(() => import("@/page/districtLanguageSetting/index")),
        showSider: true,
        modeBelong: "system",
        sideKey: "/districtLanguageSetting",
        title: "districtLanguageSetting",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "config" },
          { title: "system_preferences" },
          { title: "lang_setting", active: true },
        ],
      },

      {
        path: "/uploadResult",
        auth: true,
        authCode: 10110,
        component: lazy(() => import("@/page/uploadResult/index")),
        title: "uploadResult",
        showBreadcrumb: false,
        showSider: false,
      },

      {
        path: "/dealFault",
        auth: true,
        authCode: 17110,
        component: lazy(() => import("@/page/dealFault/index")),
        title: "dealFault",
        showBreadcrumb: false,
        showSider: false,
      },

      {
        path: "/dealFault/add",
        auth: true,
        authCode: 17111,
        component: lazy(() => import("@/page/dealFault/add")),
        title: "dealFault",
        showSider: false,
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "issues" },
          { title: "fault_item_add", active: true },
        ],
      },

      {
        path: "/dealFault/edit/:key",
        auth: true,
        authCode: 17112,
        component: lazy(() => import("@/page/dealFault/edit")),
        title: "dealFault",
        showSider: false,
        showBreadcrumb: true,
        matchPath: "/dealFault/edit",
        breadcrumbData: [
          { title: "issues" },
          { title: "fault_edit", active: true },
        ],
      },

      {
        path: "/service",
        auth: true,
        authCode: 18110,
        component: lazy(() => import("@/page/service/index")),
        title: "service",
        showBreadcrumb: false,
        showSider: false,
      },

      {
        path: "/service/add",
        auth: true,
        authCode: 18111,
        component: lazy(() => import("@/page/service/add")),
        title: "service",
        showSider: false,
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "maintenance" },
          { title: "service_add", active: true },
        ],
      },

      {
        path: "/service/edit/:key",
        auth: true,
        authCode: 18112,
        component: lazy(() => import("@/page/service/edit")),
        title: "service",
        showSider: false,
        matchPath: "/service/edit",
        showBreadcrumb: true,
        breadcrumbData: [
          { title: "maintenance" },
          { title: "service_edit", active: true },
        ],
      },

      {
        path: "/map",
        auth: true,
        authCode: 20100,
        component: lazy(() => import("@/page/map/index")),
        title: "map",
        showSider: false,
        showBreadcrumb: false,
      },

      {
        path: "/cinemaDetail/:key",
        auth: true,
        authCode: 20111,
        component: lazy(() => import("@/page/map/detail")),
        title: "map",
        matchPath: "/cinemaDetail",
        showSider: false,
        showBreadcrumb: false,
      },

      {
        path: "/deviceList",
        auth: true,
        authCode: 19110, //10110,
        component: lazy(() => import("@/page/deviceList/index")),
        title: "deviceList",
        showBreadcrumb: false,
        showSider: false,
      },

      {
        path: "/report",
        auth: true,
        authCode: 21110,
        component: lazy(() => import("@/page/report/index")),
        title: "report",
        showBreadcrumb: false,
        showSider: false,
      },

      {
        path: "/light",
        auth: true,
        authCode: 21910,
        component: lazy(() => import("@/page/report/lightReport")),
        title: "lightReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "lamp_report", active: true },
        ],
      },

      {
        path: "/newlight",
        auth: true,
        authCode: 22110,
        component: lazy(() => import("@/page/report/newlightReport")),
        title: "newlightReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "new_lamp_report", active: true },
        ],
      },
      {
        path: "/lightInstall",
        auth: true,
        authCode: 22010,
        component: lazy(() => import("@/page/report/lightInstallReport")),
        title: "lightInstallReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "lampInstall", active: true },
        ],
      },
      {
        path: "/timeTriggerReport",
        auth: true,
        authCode: 21910,
        component: lazy(() => import("@/page/report/timeReport")),
        title: "timeTriggerReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "time_report", active: true },
        ],
      },
      {
        path: "/stopReport",
        auth: true,
        authCode: 21810,
        component: lazy(() => import("@/page/report/stopReport")),
        title: "stopReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "downtime_report", active: true },
        ],
      },

      {
        path: "/guaranteeReport",
        auth: true,
        authCode: 21710,
        component: lazy(() => import("@/page/report/guaranteeReport")),
        title: "guaranteeReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "warranty_report", active: true },
        ],
      },

      {
        path: "/serviceReport",
        auth: true,
        authCode: 21510,
        component: lazy(() => import("@/page/report/serviceReport")),
        title: "serviceReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "service_report", active: true },
        ],
      },

      {
        path: "/serviceReport/add",
        auth: true,
        authCode: 21520,
        component: lazy(() => import("@/page/report/createServiceReport")),
        title: "createServiceReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "service_report" },
          { title: "service_report_add", active: true },
        ],
      },

      {
        path: "/timeReport/add",
        auth: true,
        authCode: 21520,
        component: lazy(() => import("@/page/report/createTimeReport")),
        title: "createTimeReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "time_report" },
          { title: "time_report_add", active: true },
        ],
      },

      {
        path: "/serviceReport/edit/:key",
        auth: true,
        authCode: 21530,
        component: lazy(() => import("@/page/report/updateServiceReport")),
        title: "updateServiceReport",
        showBreadcrumb: true,
        matchPath: "/serviceReport/edit",
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "service_report" },
          { title: "service_report_edit", active: true },
        ],
      },

      {
        path: "/serviceReport/preview/:key",
        auth: true,
        authCode: 21550,
        component: lazy(() => import("@/page/report/serviceReportPreview")),
        title: "serviceReportPreview",
        showBreadcrumb: true,
        showSider: false,
        matchPath: "/serviceReport/preview",
        breadcrumbData: [
          { title: "report" },
          { title: "service_report" },
          { title: "preview", active: true },
        ],
      },

      {
        path: "/repairReport",
        auth: true,
        authCode: 21410,
        component: lazy(() => import("@/page/report/repairReport")),
        title: "repairReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "repair_report", active: true },
        ],
      },

      {
        path: "/repairReport/add",
        auth: true,
        authCode: 21420,
        component: lazy(() => import("@/page/report/createRepairReport")),
        title: "createRepairReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "repair_report" },
          { title: "repair_report_add", active: true },
        ],
      },

      {
        path: "/repairReport/edit/:key",
        auth: true,
        authCode: 21430,
        component: lazy(() => import("@/page/report/updateRepairReport")),
        title: "updateRepairReport",
        showBreadcrumb: true,
        matchPath: "/repairReport/edit",
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "repair_report" },
          { title: "repair_report_edit", active: true },
        ],
      },

      {
        path: "/repairReport/preview/:key",
        auth: true,
        authCode: 21450,
        component: lazy(() => import("@/page/report/repairReportPreview")),
        title: "repairReportPreview",
        showBreadcrumb: true,
        matchPath: "/repairReport/preview",
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "repair_report" },
          { title: "preview", active: true },
        ],
      },

      {
        path: "/faultReport",
        auth: true,
        authCode: 21310,
        component: lazy(() => import("@/page/report/faultReport")),
        title: "faultReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "fault_report", active: true },
        ],
      },

      {
        path: "/faultReport/add",
        auth: true,
        authCode: 21320,
        component: lazy(() => import("@/page/report/createFaultReport")),
        title: "createFaultReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "fault_report" },
          { title: "fault_report_add", active: true },
        ],
      },

      {
        path: "/faultReport/edit/:key",
        auth: true,
        authCode: 21330,
        component: lazy(() => import("@/page/report/updateFaultReport")),
        title: "updateFaultReport",
        showBreadcrumb: true,
        matchPath: "/faultReport/edit",
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "fault_report" },
          { title: "fault_report_edit", active: true },
        ],
      },

      {
        path: "/faultReport/preview/:key",
        auth: true,
        authCode: 21350,
        component: lazy(() => import("@/page/report/faultReportPreview")),
        title: "faultReportPreview",
        matchPath: "/faultReport/preview",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "fault_report" },
          { title: "preview", active: true },
        ],
      },

      {
        path: "/serviceRecordReport",
        auth: true,
        authCode: 21610,
        component: lazy(() => import("@/page/report/serviceRecordReport")),
        title: "serviceRecordReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "service_record_report", active: true },
        ],
      },

      {
        path: "/serviceRecordReport/add",
        auth: true,
        authCode: 21620,
        component: lazy(() =>
          import("@/page/report/createServiceRecordReport")
        ),
        title: "serviceRecordReport",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "service_record_report" },
          { title: "service_record_report_add", active: true },
        ],
      },

      {
        path: "/serviceRecordReport/edit/:key",
        auth: true,
        authCode: 21630,
        component: lazy(() =>
          import("@/page/report/updateServiceRecordReport")
        ),
        title: "updateServiceRecordReport",
        showBreadcrumb: true,
        matchPath: "/serviceRecordReport/edit",
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "service_record_report" },
          { title: "service_record_report_edit", active: true },
        ],
      },

      {
        path: "/serviceRecordReport/preview/:key",
        auth: true,
        authCode: 21650,
        component: lazy(() =>
          import("@/page/report/serviceRecordReportPreview")
        ),
        title: "serviceRecordReport",
        matchPath: "/serviceRecordReport/preview",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "service_record_report" },
          { title: "preview", active: true },
        ],
      },

      {
        path: "/faultCount",
        auth: true,
        authCode: 21210,
        component: lazy(() => import("@/page/report/faultCount")),
        title: "faultCount",
        showBreadcrumb: true,
        showSider: false,
        breadcrumbData: [
          { title: "report" },
          { title: "fault_stiatistic_report", active: true },
        ],
      },

      {
        path: "/GFlight",
        auth: true,
        component: lazy(() => import("@/page/GFlight/page")),
        title: "GFlight",
        showBreadcrumb: false,
        showSider: false,
      },
    ],
  },
  {
    path: "*",
    auth: true,
    title: "Noc",
    component: lazy(() => import("@/page/commonPage/notFound")),
    showSider: false,
  },
];

//根据路径获取路由
const checkAuth = (routers, path, params) => {
  for (const data of routers) {
    if (data.path === path) {
      return data;
    } else {
      if (matchParamsPath(data.matchPath, path, params)) {
        return data;
      }
    }
    if (data.children) {
      const res = checkAuth(data.children, path, params);
      if (res) return res;
    }
  }
  return null;
};

//处理包含参数的路由
const matchParamsPath = (path, routerPath, params) => {
  let newPath = path + params;
  if (newPath === routerPath) {
    return true;
  }
  return false;
};

// 路由处理方式
const generateRouter = (routers) => {
  return routers.map((item) => {
    if (item.children) {
      item.children = generateRouter(item.children);
    }
    item.element = (
      <Suspense fallback={<div style={{ color: "#fff" }}>loading...</div>}>
        <item.component />
      </Suspense>
    );
    return item;
  });
};
const Routes = () => useRoutes(generateRouter(routes));
const checkRouterAuth = (path, params = null) => {
  let auth = null;
  auth = checkAuth(routes, path, params);
  return auth;
};

export { Routes, checkRouterAuth };
