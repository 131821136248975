export default {
  serviceData: [],
  serviceTotal: 0,
  serviceState: {
    StateNew: 0,
    StateProcess: 0,
    StateNoComplete: 0,
    StateStayAcceptance: 0,
    StateComplete: 0,
  },
  serviceInfo: {},
  serviceAllRecord: [],
  serviceType: [],
};
