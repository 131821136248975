export default {
  deviceTypeList: [],
  deviceTypeTotal: 0,
  sparepartsTypeList: [],
  sparepartsTypeTotal: 0,
  sparepartsTypeSelect: [],
  sparepartsList: [],
  sparepartsTotal: 0,
  deviceCompanyList: [],
  deviceCompanyTotal: 0,
  deviceTypeSelect: [],
  deviceModelList: [],
  deviceModelTotal: 0,
  deviceTypeSelect: [],
  deviceCompanySelect: [],
  mibTypeList: [],
  mibTypeTotal: 0,
  mibTypeSelect: [],
  deviceList: [],
  deviceTotal: 0,
  deviceModelSelect: [],
  sparepartSelectData: [],
  listDeviceAll: [],
  listDeviceTotal: 0,
  deviceUpdateTime: 0,
  deviceWarnningList: [],
  deviceNoticeList: [],
  deviceNoticeTotal: 0,
  deviceMonitor: [],
  deviceDetail: {},
  deviceWarnningTotal: 0,
  lampModelList: [],
  lampModelTotal: 0,
  lampType: [],
  lampModelDetail: {},
  lampModelSelect: [],
  lampInfo: [],
  lampInfoTotal: 0,
  lampNewInfo: [],
  lampNewInfoTotal: 0,
  lampInfoRecord: [],
  lampInfoRecordTotal: 0,
  lampInfoDetail: {},
  lampCompanyModel: [],
  lampInstallReport: [],
  lampInstallReportTotal: 0,
};
