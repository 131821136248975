import defaultState from "../state/report_state";

function reportData(state = defaultState.reportData, action) {
  switch (action.type) {
    case "SET_REPORT_LIST":
      return action.data;
    default:
      return state;
  }
}

function reportTotal(state = defaultState.reportTotal, action) {
  switch (action.type) {
    case "SET_REPORT_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function reportDetail(state = defaultState.reportDetail, action) {
  switch (action.type) {
    case "SET_REPORT_DETAIL":
      return action.data;
    default:
      return state;
  }
}

function reportCol(state = defaultState.reportCol, action) {
  switch (action.type) {
    case "SET_REPORT_PREVIEW_COL":
      return action.data;
    default:
      return state;
  }
}

function reportPreViewData(state = defaultState.reportPreViewData, action) {
  switch (action.type) {
    case "SET_REPORT_PREVIEW_DATA":
      return action.data;
    default:
      return state;
  }
}

function reportPreViewTotal(state = defaultState.reportPreViewTotal, action) {
  switch (action.type) {
    case "SET_REPORT_PREVIEW_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function guaranteeData(state = defaultState.guaranteeData, action) {
  switch (action.type) {
    case "SET_GUARANTEE_DATA":
      return action.data;
    default:
      return state;
  }
}

function guaranteeTotal(state = defaultState.guaranteeTotal, action) {
  switch (action.type) {
    case "SET_GUARANTEE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function shutdownConfData(state = defaultState.shutdownConfData, action) {
  switch (action.type) {
    case "SET_SHUTDOWNCONF_DATA":
      return action.data;
    default:
      return state;
  }
}

function shutdownConfTotal(state = defaultState.shutdownConfTotal, action) {
  switch (action.type) {
    case "SET_SHUTDOWNCONF_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function shutdownReportData(state = defaultState.shutdownReportData, action) {
  switch (action.type) {
    case "SET_SHUTDOWNREPORT_DATA":
      return action.data;
    default:
      return state;
  }
}

function shutdownReportTotal(state = defaultState.shutdownReportTotal, action) {
  switch (action.type) {
    case "SET_SHUTDOWNREPORT_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function lampReportData(state = defaultState.lampReportData, action) {
  switch (action.type) {
    case "SET_LAMPREPORT_DATA":
      return action.data;
    default:
      return state;
  }
}

function lampReportTotal(state = defaultState.lampReportTotal, action) {
  switch (action.type) {
    case "SET_LAMPREPORT_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function faultCountData(state = defaultState.faultCountData, action) {
  switch (action.type) {
    case "SET_FAULTCOUNT_DATA":
      return action.data;
    default:
      return state;
  }
}

function faultCountTotal(state = defaultState.faultCountTotal, action) {
  switch (action.type) {
    case "SET_FAULTCOUNT_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function fieldOption(state = defaultState.fieldOption, action) {
  switch (action.type) {
    case "SET_FIELDOPTION":
      return action.data;
    default:
      return state;
  }
}

export default {
  reportData,
  reportTotal,
  reportDetail,
  reportCol,
  reportPreViewData,
  reportPreViewTotal,
  guaranteeData,
  guaranteeTotal,
  shutdownConfData,
  shutdownConfTotal,
  shutdownReportData,
  shutdownReportTotal,
  lampReportData,
  lampReportTotal,
  faultCountData,
  faultCountTotal,
  fieldOption,
};
