import defaultState from "../state/company_state";

//影院地区数据
function company_list(state = defaultState.company_list, action) {
  switch (action.type) {
    case "SET_DATA":
      return action.data;
    default:
      return state;
  }
}

function company_all_list(state = defaultState.company_all_list, action) {
  switch (action.type) {
    case "SET_ALL_DATA":
      return action.data;
    default:
      return state;
  }
}

function total(state = defaultState.total, action) {
  switch (action.type) {
    case "SET_TOTAL":
      return action.data;
    default:
      return state;
  }
}

export default {
  company_list,
  company_all_list,
  total,
};
