import defaultState from "../state/dashboard_state";

//影院地区数据
function cinemaAreaData(state = defaultState.cinemaAreaData, action) {
  switch (action.type) {
    case "SET_AREA_DATA":
      return action.data;
    default:
      return state;
  }
}

function cinemaAreaAllData(state = defaultState.cinemaAreaAllData, action) {
  switch (action.type) {
    case "SET_AREA_ALL_DATA":
      return action.data;
    default:
      return state;
  }
}

function cinemaDataNum(state = defaultState.cinemaDataNum, action) {
  switch (action.type) {
    case "SET_CINEMA_NUM":
      return action.data;
    default:
      return state;
  }
}

function deviceCard(state = defaultState.deviceCard, action) {
  switch (action.type) {
    case "SET_DEVICE_CARD":
      return action.data;
    default:
      return state;
  }
}

function resource(state = defaultState.resource, action) {
  switch (action.type) {
    case "SET_RESOURCE":
      return action.data;
    default:
      return state;
  }
}

function lineData(state = defaultState.lineData, action) {
  switch (action.type) {
    case "SET_LINE_DATA":
      return action.data;
    default:
      return state;
  }
}

function barData(state = defaultState.barData, action) {
  switch (action.type) {
    case "SET_BAR_DATA":
      return action.data;
    default:
      return state;
  }
}

function fault_situation(state = defaultState.fault_situation, action) {
  switch (action.type) {
    case "FAULT_SITUATION":
      return action.data;
    default:
      return state;
  }
}

function alarm_overview(state = defaultState.alarm_overview, action) {
  switch (action.type) {
    case "ALARM_OVERVIEW":
      return action.data;
    default:
      return state;
  }
}

function lastFaultList(state = defaultState.lastFaultList, action) {
  switch (action.type) {
    case "SET_LAST_FAULT":
      return action.data;
    default:
      return state;
  }
}

function lastAlarmList(state = defaultState.lastAlarmList, action) {
  switch (action.type) {
    case "SET_LAST_ALARM":
      return action.data;
    default:
      return state;
  }
}

function expireTs(state = defaultState.expireTs, action) {
  switch (action.type) {
    case "SET_EXPIRTS":
      return action.data;
    default:
      return state;
  }
}

export default {
  cinemaAreaData,
  cinemaDataNum,
  deviceCard,
  resource,
  lineData,
  fault_situation,
  alarm_overview,
  barData,
  lastFaultList,
  lastAlarmList,
  cinemaAreaAllData,
  expireTs,
};
