import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resources from "@/locale/resources";
import Storage from "@/utils/storage";
import { fmtLang } from "@/utils/tool";
let lang;
if (Storage.loadObj("lang")) {
  lang = fmtLang(Storage.loadObj("lang")["Id"]);
} else {
  lang = "zh_cn";
}
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    resources: resources,
    useCookie: true, //使用cookie
    lng: lang,
    fallbackLng: "zh_cn", //设置确实备用语言
    getSync: true,
    debug: true,
    //keySeparator: false, // 语言包为多级的时候是否有效
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });
