import "./App.less";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "@/store/index/index";
import { Routes } from "@/router/index";
import enUs from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import { ConfigProvider } from "antd";
import { useState } from "react";
import { fmtLang } from "@/utils/tool";
import Storage from "./utils/storage";

function App() {
  const [locale, setLocale] = useState();
  store.subscribe(() => {
    if (store.getState().current_language) {
      setLocale(store.getState().current_language);
    } else {
      if (Storage.loadObj("lang")) {
        setLocale(fmtLang(Storage.loadObj("lang").Id));
      }
    }
  });
  const fmtLocale = (locale) => {
    switch (locale) {
      case "zh_cn":
        return zhCN;
      case "en":
        return enUs;
      default:
        return zhCN;
    }
  };

  return (
    <Provider store={store}>
      <ConfigProvider locale={fmtLocale(locale)}>
        <Router>
          <Routes />
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
