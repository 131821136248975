import defaultState from "../state/role_state";

function roleList(state = defaultState.roleList, action) {
  switch (action.type) {
    case "SET_LIST":
      return action.data;
    default:
      return state;
  }
}

function roleTotal(state = defaultState.roleTotal, action) {
  switch (action.type) {
    case "SET_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function permissionList(state = defaultState.permissionList, action) {
  switch (action.type) {
    case "SET_PERMISSION_LIST":
      return action.data;
    default:
      return state;
  }
}

function updateStatus(state = defaultState.updateStatus, action) {
  switch (action.type) {
    case "SET_UPDATE":
      return action.data;
    default:
      return state;
  }
}
function dump_role(state = defaultState.dump_role, action) {
  switch (action.type) {
    case "SET_DUMPROLE":
      return action.data;
    default:
      return state;
  }
}

function role_row(state = defaultState.role_row, action) {
  switch (action.type) {
    case "SET_ROW":
      return action.data;
    default:
      return state;
  }
}

export default {
  roleList,
  roleTotal,
  permissionList,
  updateStatus,
  dump_role,
  role_row,
};
