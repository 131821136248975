import localStorage from "localStorage";

const Storage = {
  save: (key, data) => {
    localStorage.setItem(key, data);
  },

  load: (key) => {
    return localStorage.getItem(key);
  },

  saveObj: (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  },

  loadObj: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },

  remove: (key) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export default Storage;
