import Http from "@/utils/httpRequest";

class Device extends Http {
  //添加设备类型
  deviceTypeUpdate(data = null) {
    return this.request({
      url: "CreateUpdateDeviceType",
      data,
    });
  }

  //设备类型列表
  ListDeviceType(data) {
    return this.request({
      url: "ListDeviceType",
      data,
    });
  }

  //备件种类管理
  ListSparepartType(data) {
    return this.request({
      url: "ListSparepartType",
      data,
    });
  }

  //备件种类更新
  UpdateSparepartType(data) {
    return this.request({
      url: "CreateUpdateSparepartType",
      data,
    });
  }

  //备件种类下拉选择
  DumpSparepartType() {
    return this.request({
      url: "DumpSparepartType",
    });
  }

  //备件列表
  ListSparepart(data) {
    return this.request({
      url: "ListSparepart",
      data,
    });
  }

  //备件选择列表
  DumpSparepart() {
    return this.request({
      url: "DumpSparepart",
    });
  }

  //更新备件
  CreateUpdateSparepart(data) {
    return this.request({
      url: "CreateUpdateSparepart",
      data,
    });
  }

  //设备厂家
  ListDeviceCompany(data) {
    return this.request({
      url: "ListDeviceCompany",
      data,
    });
  }

  //增改设备厂家
  CreateUpdateDeviceCompany(data) {
    return this.request({
      url: "CreateUpdateDeviceCompany",
      data,
    });
  }

  //设备类型选择列表
  DumpDeviceType() {
    return this.request({
      url: "DumpDeviceType",
    });
  }

  //设备型号列表
  ListDeviceModel(data) {
    return this.request({
      url: "ListDeviceModel",
      data,
    });
  }

  //设备厂家选择列表
  DumpDeviceCompany() {
    return this.request({
      url: "DumpDeviceCompany",
    });
  }

  //设备型号增改
  CreateUpdateDeviceModel(data) {
    return this.request({
      url: "CreateUpdateDeviceModel",
      data,
    });
  }

  //查MIB类型
  ListMibType(data) {
    return this.request({
      url: "ListMibType",
      data,
    });
  }

  //增改MIB类型
  CreateUpdateMibType(data) {
    return this.request({
      url: "CreateUpdateMibType",
      data,
    });
  }

  //MIB选择
  DumpMibType() {
    return this.request({
      url: "DumpMibType",
    });
  }

  //设备列表接口
  ListDevice(data) {
    return this.request({
      url: "ListDevice",
      data,
    });
  }

  //设备型号选择接口
  DumpDeviceModel(data) {
    return this.request({
      url: "DumpDeviceModel",
      data,
    });
  }

  //增改设备
  CreateUpdateDevice(data, isJump = true) {
    return this.request({
      url: "CreateUpdateDevice",
      data,
      isJump: isJump,
    });
  }

  //所有设备统计列表
  ListDeviceRunnningInfo(data) {
    return this.request({
      url: "ListDeviceRunnningInfo",
      data,
    });
  }

  //设备告警-列表
  ListDeviceRunnningInfoWarning(data) {
    return this.request({
      url: "ListDeviceRunnningInfoWarning",
      data,
    });
  }

  //设备通知-列表
  ListDeviceRunnningInfoNotice(data) {
    return this.request({
      url: "ListDeviceRunnningInfoNotice",
      data,
    });
  }

  //设备监控项列表
  ObjDeviceMonitor(data) {
    return this.request({
      url: "ObjDeviceMonitor",
      data,
    });
  }

  //设备监控历史数据图表
  ObjDeviceRunnningInfoMonitorValHistory(data) {
    return this.request({
      url: "ObjDeviceRunnningInfoMonitorValHistory",
      data,
    });
  }

  //编辑设备监控项
  CreateUpdateDeviceMonitor(data) {
    return this.request({
      url: "CreateUpdateDeviceMonitor",
      data,
    });
  }

  //设备类型图片
  ObjDeviceTypeImg() {
    return this.request({
      url: "ObjDeviceTypeImg",
    });
  }

  //设备运行状态概览
  DeviceRunnningInfoOverview(data) {
    return this.request({
      url: "DeviceRunnningInfoOverview",
      data,
    });
  }

  //远程按钮数据
  FrontEndPageElement(data) {
    return this.request({
      url: "FrontEndPageElement",
      data,
    });
  }

  //服务器SN更改记录
  ListDeviceServerChangeLogSN(data) {
    return this.request({
      url: "ListDeviceServerChangeLogSN",
      data,
    });
  }

  //增加SN更改记录
  CreateUpdateDeviceServerChangeLogSN(data) {
    return this.request({
      url: "CreateUpdateDeviceServerChangeLogSN",
      data,
    });
  }

  //增加续期记录
  CreateUpdateSystemExpired(data) {
    return this.request({
      url: "CreateUpdateSystemExpired",
      data,
    });
  }

  //续期记录
  ListSystemExpired(data) {
    return this.request({
      url: "ListSystemExpired",
      data,
    });
  }

  //设备通用选项
  ListCommonOption(data) {
    return this.request({
      url: "ListCommonOption",
      data,
    });
  }

  //设备详情
  ObjDeviceRunnningInfo(data) {
    return this.request({
      url: "ObjDeviceRunnningInfo",
      data,
    });
  }

  //灯类型
  ListLampModel(data) {
    return this.request({
      url: "/projectorlampmodel/ListLampModel",
      needBaseUrl: false,
      data,
      method: "get",
    });
  }

  //灯类型清单

  DumpSmallDataForLampType(data) {
    return this.request({
      url: "/projectorlamptype/DumpSmallDataForLampType",
      needBaseUrl: false,
      data,
    });
  }

  //灯型号删除
  DeleteLampModel(data) {
    return this.request({
      url: "/projectorlampmodel/DeleteLampModel",
      needBaseUrl: false,
      data,
    });
  }

  //灯类型添加
  CreateLampModel(data) {
    return this.request({
      url: "/projectorlampmodel/CreateLampModel",
      needBaseUrl: false,
      data,
    });
  }

  //灯类型修改
  UpdateLampModel(data) {
    return this.request({
      url: "/projectorlampmodel/CreateUpdateLampModel",
      needBaseUrl: false,
      data,
    });
  }

  //灯型号存在性检查
  CheckExistForLampModel(data) {
    return this.request({
      url: "/projectorlampmodel/CheckExistForLampModel",
      needBaseUrl: false,
      method: "get",
      data,
    });
  }

  //灯型号详情
  ObjLampModel(data) {
    return this.request({
      url: "/projectorlampmodel/LampModelInfo",
      data,
      method: "get",
      needBaseUrl: false,
    });
  }

  //灯型号清单
  DumpSmallDataForLampModel() {
    return this.request({
      url: "/projectorlampmodel/DumpSmallDataForLampModel",
      needBaseUrl: false,
      method: "get",
    });
  }

  //灯信息
  ListProjectorWithoutLamp(data) {
    return this.request({
      url: "/projectorlamp/ListProjectorWithoutLamp",
      method: "get",
      needBaseUrl: false,
      data,
    });
  }

  //添加新灯信息
  ListProjectorWithLamp(data) {
    return this.request({
      url: "/projectorlamp/ListProjectorWithLamp",
      needBaseUrl: false,
      method: "get",
      data,
    });
  }

  //批量加投影灯
  UpdateProjectorInstallLamp(data) {
    return this.request({
      url: "/projectorlamp/UpdateProjectorInstallLamp",
      needBaseUrl: false,
      data,
    });
  }

  //更换记录列表 ListProjectorInstallLampLog
  ListProjectorInstallLampLog(data) {
    return this.request({
      url: "/projectorlampchangelog/ListProjectorInstallLampLog",
      needBaseUrl: false,
      method: "get",
      data,
    });
  }

  //灯信息详情
  ObjLamp(data) {
    return this.request({
      url: "/projectorlamp/ObjLamp",
      method: "get",
      needBaseUrl: false,
      data,
    });
  }

  //查询灯信息品牌型号
  DumpLampBrandModel() {
    return this.request({
      url: "/projectorlampmodel/DumpLampBrandModel",
      method: "get",
      needBaseUrl: false,
    });
  }

  //灯信息编辑
  UpdateProjectorInstallLampWithoutLog(data) {
    return this.request({
      url: "/projectorlamp/UpdateProjectorInstallLampWithoutLog",
      needBaseUrl: false,
      data,
    });
  }

  //灯信息安装登记
  UpdateProjectorInstallLampWithLog(data) {
    return this.request({
      url: "/projectorlampchangelog/UpdateProjectorInstallLampWithLog",
      needBaseUrl: false,
      data,
    });
  }

  //灯安装报告列表
  ListLampInstallLogReport(data) {
    return this.request({
      url: "/projectorlampchangelog/ListLampInstallLogReport",
      needBaseUrl: false,
      method: "get",
      data,
    });
  }

  //灯安装报告导出
  ListLampInstallLogReportExport(data) {
    return this.request({
      url: "/projectorlampchangelog/ListLampInstallLogReportExport",
      needBaseUrl: false,
      data,
    });
  }
}

export default Device;
