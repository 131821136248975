export default {
  reportData: [],
  reportTotal: 0,
  reportDetail: {},
  reportCol: [],
  reportPreViewData: [],
  reportPreViewTotal: 0,
  guaranteeData: [],
  guaranteeTotal: 0,
  shutdownConfData: [],
  shutdownConfTotal: 0,
  shutdownReportData: [],
  shutdownReportTotal: 0,
  lampReportData: [],
  lampReportTotal: 0,
  faultCountData: [],
  faultCountTotal: 0,
  fieldOption: [],
};
