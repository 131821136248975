export default {
  faultTypeList: [],
  faultTypeTotal: 0,
  faultTypeItemList: [],
  faultTypeItemTotal: 0,
  faultTypeSelect: [],
  faultActionList: [],
  faultActionTotal: 0,
  deviceControlList: [],
  deviceControlTotal: 0,
  faultTypeItemSelect: [],
  faultList: [],
  faultTotal: 0,
  faultStatus: {
    NewState: 0,
    NotSolveState: 0,
    SolveState: 0,
    CloseState: 0,
  },
  faultDetail: {},
  faultFeed: [],
  faultChart: {},
};
