import defaultState from "../state/service_state";

function serviceData(state = defaultState.serviceData, action) {
  switch (action.type) {
    case "SET_SERVICE_DATA":
      return action.data;
    default:
      return state;
  }
}

function serviceTotal(state = defaultState.serviceTotal, action) {
  switch (action.type) {
    case "SET_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function serviceState(state = defaultState.serviceState, action) {
  switch (action.type) {
    case "SET_SERVICE_STATUS":
      return action.data;
    default:
      return state;
  }
}

function serviceInfo(state = defaultState.serviceInfo, action) {
  switch (action.type) {
    case "SET_SERVICE_INFO":
      return action.data;
    default:
      return state;
  }
}

function serviceAllRecord(state = defaultState.serviceAllRecord, action) {
  switch (action.type) {
    case "SET_SERVICE_ALL_RECORD":
      return action.data;
    default:
      return state;
  }
}

function serviceType(state = defaultState.serviceType, action) {
  switch (action.type) {
    case "SET_SERVICE_TYPE":
      return action.data;
    default:
      return state;
  }
}

export default {
  serviceData,
  serviceTotal,
  serviceState,
  serviceInfo,
  serviceAllRecord,
  serviceType,
};
