import defaultState from "../state/fault_state";

function faultTypeList(state = defaultState.faultTypeList, action) {
  switch (action.type) {
    case "SET_FAULT_TYPE_DATA":
      return action.data;
    default:
      return state;
  }
}

function faultTypeTotal(state = defaultState.faultTypeTotal, action) {
  switch (action.type) {
    case "SET_FAULT_TYPE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function faultTypeItemList(state = defaultState.faultTypeItemList, action) {
  switch (action.type) {
    case "SET_FAULT_TYPE_ITEM_DATA":
      return action.data;
    default:
      return state;
  }
}

function faultTypeItemTotal(state = defaultState.faultTypeItemTotal, action) {
  switch (action.type) {
    case "SET_FAULT_TYPE_ITEM_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function faultTypeSelect(state = defaultState.faultTypeSelect, action) {
  switch (action.type) {
    case "SET_FAULT_TYPE_SELECT":
      return action.data;
    default:
      return state;
  }
}

function faultActionList(state = defaultState.faultActionList, action) {
  switch (action.type) {
    case "SET_FAULT_ACTION_DATA":
      return action.data;
    default:
      return state;
  }
}

function faultActionTotal(state = defaultState.faultActionTotal, action) {
  switch (action.type) {
    case "SET_FAULT_ACTION_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function deviceControlList(state = defaultState.deviceControlList, action) {
  switch (action.type) {
    case "SET_DEVICE_CONTROL_DATA":
      return action.data;
    default:
      return state;
  }
}

function deviceControlTotal(state = defaultState.deviceControlTotal, action) {
  switch (action.type) {
    case "SET_DEVICE_CONTROL_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function faultTypeItemSelect(state = defaultState.faultTypeItemSelect, action) {
  switch (action.type) {
    case "SET_FAULT_OPTION_SELECT":
      return action.data;
    default:
      return state;
  }
}

function faultList(state = defaultState.faultList, action) {
  switch (action.type) {
    case "SET_FAULT_DATA":
      return action.data;
    default:
      return state;
  }
}

function faultTotal(state = defaultState.faultTotal, action) {
  switch (action.type) {
    case "SET_FAULT_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function faultStatus(state = defaultState.faultStatus, action) {
  switch (action.type) {
    case "SET_FAULT_STATUS":
      return action.data;
    default:
      return state;
  }
}

function faultDetail(state = defaultState.faultDetail, action) {
  switch (action.type) {
    case "SET_FAULT_DETAIL":
      return action.data;
    default:
      return state;
  }
}

//

function faultFeed(state = defaultState.faultFeed, action) {
  switch (action.type) {
    case "SET_FAULT_FEED":
      return action.data;
    default:
      return state;
  }
}

function faultChart(state = defaultState.faultChart, action) {
  switch (action.type) {
    case "SET_FAULT_CHART":
      return action.data;
    default:
      return state;
  }
}
export default {
  faultTypeList,
  faultTypeTotal,
  faultTypeItemList,
  faultTypeItemTotal,
  faultTypeSelect,
  faultActionList,
  faultActionTotal,
  deviceControlList,
  deviceControlTotal,
  faultTypeItemSelect,
  faultList,
  faultTotal,
  faultStatus,
  faultDetail,
  faultFeed,
  faultChart,
};
