const BaseConfig = {
  BASE_URL: FormatApi().api, //"http://112.95.245.251",
  Version: "1.0.2",
  FileDomain: FormatApi().downLoadUrl,
};

function FormatApi() {
  let API_DOMAIN;
  let IMG_URL;
  let http_domain = window.location.host;
  let DOWN_LOAD;
  let protocal = window.location.protocol;
  //判断是生产环境还是开发
  if (process.env.NODE_ENV === "development") {
    API_DOMAIN = `https://test.gdc-noc.com`; //"https://sztest.gdc-noc.com/gateway" //"http://test.gdc-noc.com:18001/gateway";  //"http://dev.szkbrs.com/gateway";
    DOWN_LOAD = `https://test.gdc-noc.com`; //"https://sztest.gdc-noc.com"// //"http://dev.szkbrs.com"; //
    IMG_URL = "";
  } else {
    API_DOMAIN = `${protocal}//${http_domain}`;
    DOWN_LOAD = `${protocal}//${http_domain}`;
  }
  return { api: API_DOMAIN, src: IMG_URL, downLoadUrl: DOWN_LOAD };
}

export default BaseConfig;
