export default {
  cinemaGroupSeletData: [],
  chainSelectData: [],
  cinemaList: [],
  cinemaTotal: 0,
  cinemaHallList: [],
  cinemaHallTotal: 0,
  cinemaSelectData: [],
  chainsList: [],
  chainsTotal: 0,
  cinemaListByDistrict: [],
  cinemaGroupList: [],
  cinemaGroupTotal: 0,
  cinemaHallSelect: [],
  cinemaUserList: [],
  cinemUserPosition: [],
  cinemaNumLimit: 0,
  cinemacontact: [],
  cinemacontact_total: 0,
  cinemaMapHall: [],
  server: {},
  projector: [],
  soundProcessor: {},
  automatedControlBox: {},
  other: {},
};
