import defaultState from "../state/common_state";

//异步加载loading
function loading(state = defaultState.loading, action) {
  switch (action.type) {
    case "SET_LOADING":
      return action.data;
    default:
      return state;
  }
}

function current(state = defaultState.current, action) {
  switch (action.type) {
    case "SET_CURRENT":
      return action.data;
    default:
      return state;
  }
}

function languageList(state = defaultState.languageList, action) {
  switch (action.type) {
    case "SET_LANGUAGE":
      return action.data;
    default:
      return state;
  }
}

function districtList(state = defaultState.districtList, action) {
  switch (action.type) {
    case "SET_DISTRICT":
      return action.data;
    default:
      return state;
  }
}

function current_language(state = defaultState.current_language, action) {
  switch (action.type) {
    case "SET_CURRENT_LANGUAGE":
      return action.data;
    default:
      return state;
  }
}

export default {
  loading,
  current,
  languageList,
  districtList,
  current_language,
};
