import defaultState from "../state/area_state";

function areaData(state = defaultState.areaData, action) {
  switch (action.type) {
    case "SET_AREA_DATA":
      return action.data;
    default:
      return state;
  }
}

function areaAllData(state = defaultState.areaAllData, action) {
  switch (action.type) {
    case "SET_All_AREA_DATA":
      return action.data;
    default:
      return state;
  }
}

function mapData(state = defaultState.mapData, action) {
  switch (action.type) {
    case "SET_MAP_DATA":
      return action.data;
    default:
      return state;
  }
}

export default {
  areaData,
  areaAllData,
  mapData,
};
