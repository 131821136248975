import defaultState from "../state/cinema_state";

function cinemaGroupSeletData(
  state = defaultState.cinemaGroupSeletData,
  action
) {
  switch (action.type) {
    case "SET_CINEMAGROUP_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function chainSelectData(state = defaultState.chainSelectData, action) {
  switch (action.type) {
    case "SET_CHAIN_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function cinemaList(state = defaultState.cinemaList, action) {
  switch (action.type) {
    case "SET_CINEMA_DATA":
      return action.data;
    default:
      return state;
  }
}

function cinemaTotal(state = defaultState.cinemaTotal, action) {
  switch (action.type) {
    case "SET_CINEMA_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function cinemaHallList(state = defaultState.cinemaHallList, action) {
  switch (action.type) {
    case "SET_HALL_LIST":
      return action.data;
    default:
      return state;
  }
}

function cinemaHallTotal(state = defaultState.cinemaHallTotal, action) {
  switch (action.type) {
    case "SET_HALL_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function cinemaSelectData(state = defaultState.cinemaSelectData, action) {
  switch (action.type) {
    case "SET_CINEMA_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function chainsList(state = defaultState.chainsList, action) {
  switch (action.type) {
    case "SET_CHAINS_LIST":
      return action.data;
    default:
      return state;
  }
}

function chainsTotal(state = defaultState.chainsTotal, action) {
  switch (action.type) {
    case "SET_CHAINS_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function cinemaListByDistrict(
  state = defaultState.cinemaListByDistrict,
  action
) {
  switch (action.type) {
    case "SET_CINEMADATA_BY_DISTRICT":
      return action.data;
    default:
      return state;
  }
}

function cinemaGroupList(state = defaultState.cinemaGroupList, action) {
  switch (action.type) {
    case "SET_CINEMAGROUP_LIST":
      return action.data;
    default:
      return state;
  }
}

function cinemaGroupTotal(state = defaultState.cinemaGroupTotal, action) {
  switch (action.type) {
    case "SET_CINEMAGROUP_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function cinemaHallSelect(state = defaultState.cinemaHallSelect, action) {
  switch (action.type) {
    case "SET_HALL_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function cinemaUserList(state = defaultState.cinemaUserList, action) {
  switch (action.type) {
    case "SET_CINEMA_CONTACT":
      return action.data;
    default:
      return state;
  }
}

function cinemUserPosition(state = defaultState.cinemUserPosition, action) {
  switch (action.type) {
    case "SET_CINEMA_CONTACT_POSITION":
      return action.data;
    default:
      return state;
  }
}

function cinemaNumLimit(state = defaultState.cinemaNumLimit, action) {
  switch (action.type) {
    case "SET_HALL_NUMLIMIT":
      return action.data;
    default:
      return state;
  }
}

function cinemacontact(state = defaultState.cinemacontact, action) {
  switch (action.type) {
    case "SET_CINEMA_CONTACT_USER":
      return action.data;
    default:
      return state;
  }
}

function cinemacontact_total(state = defaultState.cinemacontact_total, action) {
  switch (action.type) {
    case "SET_CINEMA_CONTACT_USER_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function cinemaMapHall(state = defaultState.cinemaMapHall, action) {
  switch (action.type) {
    case "SET_CINEMA_MAP_HALL":
      return action.data;
    default:
      return state;
  }
}

function server(state = defaultState.server, action) {
  switch (action.type) {
    case "SET_SERVER":
      return action.data;
    default:
      return state;
  }
}

function projector(state = defaultState.projector, action) {
  switch (action.type) {
    case "SET_PROJECTOR":
      return action.data;
    default:
      return state;
  }
}

function soundProcessor(state = defaultState.soundProcessor, action) {
  switch (action.type) {
    case "SET_SOUNDPROCESSOR":
      return action.data;
    default:
      return state;
  }
}

function automatedControlBox(state = defaultState.automatedControlBox, action) {
  switch (action.type) {
    case "SET_AUTOMATEDCONTROLBOX":
      return action.data;
    default:
      return state;
  }
}

function other(state = defaultState.other, action) {
  switch (action.type) {
    case "SET_OTHER":
      return action.data;
    default:
      return state;
  }
}

export default {
  cinemaGroupSeletData,
  chainSelectData,
  cinemaList,
  cinemaTotal,
  cinemaHallList,
  cinemaHallTotal,
  cinemaSelectData,
  chainsList,
  chainsTotal,
  cinemaListByDistrict,
  cinemaGroupList,
  cinemaGroupTotal,
  cinemaHallSelect,
  cinemaUserList,
  cinemUserPosition,
  cinemaNumLimit,
  cinemacontact,
  cinemacontact_total,
  cinemaMapHall,
  server,
  projector,
  soundProcessor,
  automatedControlBox,
  other,
};
