import axios from "axios";
import { message } from "antd";
import BaseConfig from "@/utils/config";
import Storage from "@/utils/storage";
import i18n from "i18next";
class Http {
  constructor() {
    this.BaseUrl = BaseConfig.BASE_URL;
  }
  request({
    url,
    header = {},
    method = "post",
    data,
    needBaseUrl = true,
    needToken = true,
    isJump = true,
  }) {
    return new Promise((resolve, reject) => {
      this._request(
        url,
        method,
        header,
        data,
        needBaseUrl,
        needToken,
        isJump,
        resolve,
        reject
      );
    });
  }

  _request(
    url,
    method,
    header,
    data,
    needBaseUrl,
    needToken,
    isJump,
    resolve,
    reject
  ) {
    //axios.defaults.headers.common['token']
    let params = {};
    let debug;
    let whiteList = ["test.gdc-noc.com"];
    let http_domain = window.location.host;
    if (process.env.NODE_ENV === "development") {
      debug = true;
    } else {
      if (whiteList.indexOf(http_domain) !== -1) {
        debug = true;
      } else {
        debug = false;
      }
    }
    if (needToken) {
      let attach = {
        "X-AccessToken": ` ${Storage.load("token")}`,
        "X-Action": url,
      };
      header = Object.assign(attach, header);
    } else {
      let attach = {
        "X-Action": url,
      };
      header = Object.assign(attach, header);
    }
    const dealUrl = (debug, needBaseUrl) => {
      let result = "";
      if (needBaseUrl) {
        result = this.BaseUrl + "/gateway";
      } else {
        result = this.BaseUrl + "/apiweb" + url;
      }
      if (debug) {
        result = `${result}`;
      }
      return result;
    };

    if (method === "get") {
      params = {
        method: method,
        url: dealUrl(debug, needBaseUrl),
        params: data,
        headers: header,
      };
    } else if (method === "post") {
      params = {
        method: method,
        url: dealUrl(debug, needBaseUrl),
        data,
        headers: header,
      };
    }

    axios(params)
      .then((res) => {
        this.resSuccess(resolve, reject, res, needBaseUrl, isJump);
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 403:
              window.location.href = "/403";
              break;
            case 401:
              Storage.clear();
              window.location.href = "/login";
              break;
            default:
              break;
          }
        }
        reject(err);
      });
  }

  resSuccess(resolve, reject, res, needBaseUrl, isJump) {
    let code;
    if (needBaseUrl) {
      code = res.data.Code;
    } else {
      code = res.data.code;
    }
    switch (code) {
      case 0:
        resolve(res.data);
        break;
      case 404:
        resolve(res.data);
        break;
      case 400:
        resolve(res.data);
        break;
      case 400300:
        //console.log(res.data.Msg);
        message.success({
          duration: 1.5,
          content: i18n.t("edit_success"),
          onClose: () => {
            if (isJump) {
              window.history.back();
            }
            resolve(res.data);
          },
        });
        /* message.error(res.data.Msg)
        reject(res.data); */
        break;
      case 400200:
        Storage.clear();
        window.location.href = "/login";
        break;
      case 400301:
        resolve(res.data);
        break;
      case 400303:
        resolve(res.data);
        break;
      case 400402:
        resolve(res.data);
        break;
      case 400302:
        resolve(res.data);
        break;
      case 400201:
        message.warning({
          duration: 1.5,
          content:
            "The number of Screen connections exceeds the upper limit.   Procedure Login is currently restricted, please contact the software provider.",
        });
        break;
      case 400405:
        message.error({
          duration: 1.5,
          content: res.data.Msg,
        });
        break;
      default:
        if (res.data.Msg) {
          message.error({
            duration: 1.5,
            content: res.data.Msg,
          });
        }
        reject(res.data);
        break;
    }
  }
}

export default Http;
