import page_reducer from "./page_reducer";
import dashboard_reducer from "./dashboard_reducer";
import user_reducer from "./user_reducer";
import role_reducer from "./role_reducer";
import company_reducer from "./company_reducer";
import common_reducer from "./common_reducer";
import cinema_reducer from "./cinema_reducer";
import area_reducer from "./area_reducer";
import device_reducer from "./device_reducer";
import fault_reducer from "./fault_reducer";
import service_reducer from "./service_reducer";
import report_reducer from "./report_reducer";
import { combineReducers } from "redux";

const reducerObject = combineReducers({
  ...page_reducer,
  ...dashboard_reducer,
  ...user_reducer,
  ...role_reducer,
  ...company_reducer,
  ...common_reducer,
  ...cinema_reducer,
  ...area_reducer,
  ...device_reducer,
  ...fault_reducer,
  ...service_reducer,
  ...report_reducer,
});

export default reducerObject;
