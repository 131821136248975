import defaultState from "../state/page_state";

function infoList(state = defaultState.infoList, action) {
  switch (action.type) {
    case "SET_INFO_LIST":
      return action.data;
    default:
      return state;
  }
}

function total(state = defaultState.total, action) {
  switch (action.type) {
    case "SET_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function ZzbcodeData(state = defaultState.ZzbcodeData, action) {
  switch (action.type) {
    case "SET_ZzbcodeData":
      return action.data;
    default:
      return state;
  }
}

function LightTypeData(state = defaultState.LightTypeData, action) {
  switch (action.type) {
    case "SET_LightTypeData":
      return action.data;
    default:
      return state;
  }
}

function showSider(state = defaultState.showSider, action) {
  switch (action.type) {
    case "SET_SHOW_SIDER":
      return action.data;
    case "SET_HIDE_SIDER":
      return 0;
    default:
      return state;
  }
}

export default {
  infoList,
  total,
  ZzbcodeData,
  LightTypeData,
  showSider,
};
