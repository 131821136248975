export default {
  lang: "",
  loginStatus: false,
  Uid: null,
  AccessToken: null,
  userData: [],
  userTotal: 0,
  user_row: {},
  Permission: [],
  user_cinema_data: {},
  user_select: [],
};
