import defaultState from "../state/device_state";

function deviceTypeList(state = defaultState.deviceTypeList, action) {
  switch (action.type) {
    case "SET_DEVICE_TYPE_DATA":
      return action.data;
    default:
      return state;
  }
}

function deviceTypeTotal(state = defaultState.deviceTypeTotal, action) {
  switch (action.type) {
    case "SET_DEVICE_TYPE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function sparepartsTypeList(state = defaultState.sparepartsTypeList, action) {
  switch (action.type) {
    case "SET_SPAREPARTS_TYPE_DATA":
      return action.data;
    default:
      return state;
  }
}

function sparepartsTypeTotal(state = defaultState.sparepartsTypeTotal, action) {
  switch (action.type) {
    case "SET_SPAREPARTS_TYPE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function sparepartsTypeSelect(
  state = defaultState.sparepartsTypeSelect,
  action
) {
  switch (action.type) {
    case "SET_SPAREPARTSTYPE_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function sparepartsList(state = defaultState.sparepartsList, action) {
  switch (action.type) {
    case "SET_SPAREPARTS_DATA":
      return action.data;
    default:
      return state;
  }
}

function sparepartsTotal(state = defaultState.sparepartsTotal, action) {
  switch (action.type) {
    case "SET_SPAREPARTS_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function deviceCompanyList(state = defaultState.deviceCompanyList, action) {
  switch (action.type) {
    case "SET_DEVICECOMPANY_DATA":
      return action.data;
    default:
      return state;
  }
}

function deviceCompanyTotal(state = defaultState.deviceCompanyTotal, action) {
  switch (action.type) {
    case "SET_DEVICECOMPANY_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function deviceTypeSelect(state = defaultState.deviceTypeSelect, action) {
  switch (action.type) {
    case "SET_DEVICETYPE_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function deviceModelList(state = defaultState.deviceModelList, action) {
  switch (action.type) {
    case "SET_DEVICEMODEL_DATA":
      return action.data;
    default:
      return state;
  }
}

function deviceModelTotal(state = defaultState.deviceModelTotal, action) {
  switch (action.type) {
    case "SET_DEVICEMODEL_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function deviceCompanySelect(state = defaultState.deviceCompanySelect, action) {
  switch (action.type) {
    case "SET_MANUFACTOR_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function mibTypeList(state = defaultState.mibTypeList, action) {
  switch (action.type) {
    case "SET_MIB_TYPE_DATA":
      return action.data;
    default:
      return state;
  }
}

function mibTypeTotal(state = defaultState.mibTypeTotal, action) {
  switch (action.type) {
    case "SET_MIB_TYPE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function mibTypeSelect(state = defaultState.mibTypeSelect, action) {
  switch (action.type) {
    case "SET_MIB_TYPE_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function deviceList(state = defaultState.deviceList, action) {
  switch (action.type) {
    case "SET_DEVICE_DATA":
      return action.data;
    default:
      return state;
  }
}

function deviceTotal(state = defaultState.deviceTotal, action) {
  switch (action.type) {
    case "SET_DEVICE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function deviceModelSelect(state = defaultState.deviceModelSelect, action) {
  switch (action.type) {
    case "SET_DEVICE_MODEL_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function sparepartSelectData(state = defaultState.sparepartSelectData, action) {
  switch (action.type) {
    case "SET_SPAREPARTS_SELECT_DATA":
      return action.data;
    default:
      return state;
  }
}

function listDeviceAll(state = defaultState.listDeviceAll, action) {
  switch (action.type) {
    case "SET_DEVICE_All_DATA":
      return action.data;
    default:
      return state;
  }
}

function listDeviceTotal(state = defaultState.listDeviceTotal, action) {
  switch (action.type) {
    case "SET_DEVICE_All_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function deviceUpdateTime(state = defaultState.deviceUpdateTime, action) {
  switch (action.type) {
    case "SET_DEVICE_UPDATE_TIME":
      return action.data;
    default:
      return state;
  }
}

function deviceWarnningList(state = defaultState.deviceWarnningList, action) {
  switch (action.type) {
    case "SET_DEVICE_WARNNING":
      return action.data;
    default:
      return state;
  }
}

function deviceNoticeList(state = defaultState.deviceNoticeList, action) {
  switch (action.type) {
    case "SET_DEVICE_NOTICE":
      return action.data;
    default:
      return state;
  }
}

function deviceNoticeTotal(state = defaultState.deviceNoticeTotal, action) {
  switch (action.type) {
    case "SET_DEVICE_NOTICE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function deviceMonitor(state = defaultState.deviceMonitor, action) {
  switch (action.type) {
    case "SET_DEVICE_MONITOR":
      return action.data;
    default:
      return state;
  }
}

function deviceDetail(state = defaultState.deviceDetail, action) {
  switch (action.type) {
    case "SET_DEVICE_DETAIL":
      return action.data;
    default:
      return state;
  }
}

function deviceWarnningTotal(state = defaultState.deviceWarnningTotal, action) {
  switch (action.type) {
    case "SET_DEVICE_WARNNING_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function lampModelList(state = defaultState.lampModelList, action) {
  switch (action.type) {
    case "SET_LAMP_MODEL":
      return action.data;
    default:
      return state;
  }
}

function lampModelTotal(state = defaultState.lampModelTotal, action) {
  switch (action.type) {
    case "SET_LAMP_MODEL_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function lampType(state = defaultState.lampType, action) {
  switch (action.type) {
    case "SET_LAMP_TYPE":
      return action.data;
    default:
      return state;
  }
}

function lampModelDetail(state = defaultState.lampModelDetail, action) {
  switch (action.type) {
    case "SET_LAMP_TYPE_DETAIL":
      return action.data;
    default:
      return state;
  }
}

function lampModelSelect(state = defaultState.lampModelSelect, action) {
  switch (action.type) {
    case "SET_LAMP_MODEL_SELECT":
      return action.data;
    default:
      return state;
  }
}

function lampInfo(state = defaultState.lampInfo, action) {
  switch (action.type) {
    case "SET_LAMP_INFO":
      return action.data;
    default:
      return state;
  }
}

function lampInfoTotal(state = defaultState.lampInfoTotal, action) {
  switch (action.type) {
    case "SET_LAMP_INFO_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function lampNewInfo(state = defaultState.lampNewInfo, action) {
  switch (action.type) {
    case "SET_NEW_LAMP_INFO":
      return action.data;
    default:
      return state;
  }
}

function lampNewInfoTotal(state = defaultState.lampNewInfoTotal, action) {
  switch (action.type) {
    case "SET_NEW_LAMP_INFO_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function lampInfoRecord(state = defaultState.lampInfoRecord, action) {
  switch (action.type) {
    case "SET_LAMP_INFO_CHANGE_RECODE":
      return action.data;
    default:
      return state;
  }
}

function lampInfoRecordTotal(state = defaultState.lampInfoRecordTotal, action) {
  switch (action.type) {
    case "SET_LAMP_INFO_CHANGE_RECODE_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function lampInfoDetail(state = defaultState.lampInfoDetail, action) {
  switch (action.type) {
    case "SET_LAMP_INFO_DETAIL":
      return action.data;
    default:
      return state;
  }
}

function lampCompanyModel(state = defaultState.lampCompanyModel, action) {
  switch (action.type) {
    case "SET_LAMP_COMPANY_MODEL":
      return action.data;
    default:
      return state;
  }
}

function lampInstallReport(state = defaultState.lampInstallReport, action) {
  switch (action.type) {
    case "SET_LAMP_INSTALL_REPORT":
      return action.data;
    default:
      return state;
  }
}

function lampInstallReportTotal(
  state = defaultState.lampInstallReportTotal,
  action
) {
  switch (action.type) {
    case "SET_LAMP_INSTALL_REPORT_TOTAL":
      return action.data;
    default:
      return state;
  }
}

export default {
  deviceTypeList,
  deviceTypeTotal,
  sparepartsTypeList,
  sparepartsTypeTotal,
  sparepartsTypeSelect,
  sparepartsList,
  sparepartsTotal,
  deviceCompanyList,
  deviceCompanyTotal,
  deviceTypeSelect,
  deviceModelList,
  deviceModelTotal,
  deviceCompanySelect,
  mibTypeList,
  mibTypeTotal,
  mibTypeSelect,
  deviceList,
  deviceTotal,
  deviceModelSelect,
  sparepartSelectData,
  listDeviceAll,
  listDeviceTotal,
  deviceUpdateTime,
  deviceWarnningList,
  deviceNoticeList,
  deviceNoticeTotal,
  deviceMonitor,
  deviceDetail,
  deviceWarnningTotal,
  lampModelList,
  lampModelTotal,
  lampType,
  lampModelDetail,
  lampModelSelect,
  lampInfo,
  lampInfoTotal,
  lampNewInfo,
  lampNewInfoTotal,
  lampInfoRecord,
  lampInfoRecordTotal,
  lampInfoDetail,
  lampCompanyModel,
  lampInstallReport,
  lampInstallReportTotal,
};
