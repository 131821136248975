import defaultState from "../state/user_state";
import commonState from "../state/common_state";
import Storage from "@/utils/storage";
function lang(state = commonState.current_language, action) {
  switch (action.type) {
    case "SET_LANG":
      return action.data;
    default:
      return state;
  }
}

function loginStatus(state = defaultState.loginStatus, action) {
  const token = Storage.load("token");
  if (token) {
    return true;
  } else {
    switch (action.type) {
      case "LOGIN":
        return action.status;
      case "LOGOUT":
        return action.status;
      default:
        console.log("默认");
        return state;
    }
  }
}

function AccessToken(state = defaultState.AccessToken, action) {
  switch (action.type) {
    case "SET_TOKEN":
      return action.data;
    default:
      return state;
  }
}

function Uid(state = defaultState.Uid, action) {
  switch (action.type) {
    case "SET_UID":
      return action.data;
    default:
      return state;
  }
}

function userData(state = defaultState.userData, action) {
  switch (action.type) {
    case "SET_USER_DATA":
      return action.data;
    default:
      return state;
  }
}

function userTotal(state = defaultState.userTotal, action) {
  switch (action.type) {
    case "SET_TOTAL":
      return action.data;
    default:
      return state;
  }
}

function user_row(state = defaultState.user_row, action) {
  switch (action.type) {
    case "SET_ROW":
      return action.data;
    default:
      return state;
  }
}

function user_cinema_data(state = defaultState.user_cinema_data, action) {
  switch (action.type) {
    case "SET_USER_CINEMA_DATA":
      return action.data;
    default:
      return state;
  }
}

function user_select(state = defaultState.user_select, action) {
  switch (action.type) {
    case "SET_USER_SELECT":
      return action.data;
    default:
      return state;
  }
}

export default {
  lang,
  loginStatus,
  AccessToken,
  Uid,
  userData,
  userTotal,
  user_row,
  user_cinema_data,
  user_select,
};
