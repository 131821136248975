import Http from "@/utils/httpRequest";
class CommonApi extends Http {
  //状态更改 抽象删除
  deleteMethod(data) {
    return this.request({
      url: "UpdateStatusBatch",
      data,
    });
  }

  //查重
  checkRepeat(data) {
    return this.request({
      url: "ObjCheckExist",
      data,
    });
  }

  //下载模板文件
  //场景值 1=下载影院数据对应的模板文件 2=下载影厅数据对应的模板文件
  downloadFile(data) {
    return this.request({
      url: "DLFile",
      data,
    });
  }

  //上传EXCEL
  //场景值 1=下载影院数据对应的模板文件 2=下载影厅数据对应的模板文件
  uploadFile(data) {
    return this.request({
      url: "UploadSheet",
      data,
    });
  }

  //配置
  ListCommonConf(data) {
    return this.request({
      url: "ListCommonConf",
      data,
    });
  }

  //配置更新
  CreateUpdateCommonConf(data) {
    return this.request({
      url: "CreateUpdateCommonConf",
      data,
    });
  }

  //查询地区和语言
  DumpDistrictLanguage() {
    return this.request({
      url: "DumpDistrictLanguage",
    });
  }

  //更新语言
  UpdateUserDistrictLanguage(data) {
    return this.request({
      url: "UpdateUserDistrictLanguage",
      data,
    });
  }

  //上传
  UploadFileCommon(data) {
    return this.request({
      header: { "Content-Type": "multipart/form-data" },
      url: "UploadFile",
      data,
    });
  }

  //查询文件
  SearchFile(data) {
    return this.request({
      url: "ListAttachment",
      data,
    });
  }

  //修改文件名称
  UpdateFile(data) {
    return this.request({
      url: "CreateUpdateAttachment",
      data,
    });
  }

  //上传影厅图片/文件
  HallUploadFile(data) {
    return this.request({
      url: "HallUploadFile",
      data,
    });
  }
}

export default CommonApi;
