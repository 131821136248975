export default {
  cinemaAreaData: {},
  cinemaAreaAllData: {},
  cinemaDataNum: 0,
  deviceCard: {}, //设备计数
  resource: {}, //资源
  lineData: {}, //折线图
  barData: {}, //条形图
  fault_situation: {}, //故障概况
  alarm_overview: {}, //告警概况
  lastFaultList: [], //最新设备故障
  lastAlarmList: [], //最新告警
  expireTs: 1, //到期天数
};
